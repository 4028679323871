import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import BlogListing from '../components/BlogListing';

const BlogArchive = () => {
  const {
    datoCmsBlogArchive: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      listingOverline,
      listingHeading,
      listingText,
    },
    allDatoCmsBlog: { nodes },
  } = useStaticQuery(graphql`
    query BlogArchiveQuery {
      datoCmsBlogArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerImage {
          ...SubPageBannerImageFragment
        }
        bannerText
        listingOverline
        listingHeading
        listingText
      }
      allDatoCmsBlog(sort: { order: DESC, fields: meta___firstPublishedAt }) {
        nodes {
          ...BlogListingItemFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          heading={title}
          image={bannerImage}
          text={bannerText}
          isBlogArchive={true}
        />
        <BlogListing
          overline={listingOverline}
          heading={listingHeading}
          text={listingText}
          items={nodes}
          isBlogArchive={true}
        />
      </main>
    </Layout>
  );
};

export default BlogArchive;

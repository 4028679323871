import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
} from '../styles';
import { Container, Link, SectionHeader, Tag } from './ui';
import { buildUrl } from '../utils';
import grassBg from '../images/grass-bg.svg';

const StyledBlogListing = styled.section`
  ${sectionPaddings()};
  background: url('${grassBg}') no-repeat calc(100% + 50px) calc(100% + 50px) /
    200px ${standardColours.lightGrey};
`;

const StyledBlogCards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 34px;
  margin-top: 50px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${minBreakpointQuery.large`
    grid-template-columns: repeat(${({ isBlogArchive }) =>
      isBlogArchive ? '3' : '4'}, 1fr);
  `};
`;

const StyledBlogCard = styled.article`
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour.hex : standardColours.white};
  border-radius: 20px;
  padding: 30px 20px;
  cursor: pointer;
`;

const StyledHeading = styled.h3`
  ${fontSize(22)};
  margin: 16px 0;
  font-weight: ${fontWeights.semibold};
  line-height: 1.4;
`;

const StyledExcerpt = styled.p`
  ${fontSize(14)};
  line-height: 1.7;
`;

const StyledLink = styled(Link)`
  margin-top: 14px;
  ${fontSize(14)};
  border-bottom: 1px solid ${brandColours.primary};
`;

const BlogListing = ({ overline, heading, text, items, isBlogArchive }) => {
  const {
    allDatoCmsBlog: { nodes },
  } = useStaticQuery(graphql`
    query BlogListingQuery {
      allDatoCmsBlog(
        limit: 4
        sort: { order: DESC, fields: meta___firstPublishedAt }
      ) {
        nodes {
          ...BlogListingItemFragment
        }
      }
    }
  `);

  const posts = items || nodes;

  return (
    <StyledBlogListing>
      <Container>
        <SectionHeader overline={overline} heading={heading} text={text} />
        <StyledBlogCards isBlogArchive={isBlogArchive}>
          {posts.map(({ title, slug, excerpt, category }, id) => {
            const urlPath = `blog/${slug}`;

            return (
              <StyledBlogCard
                key={id}
                backgroundColour={category.backgroundColour}
                onClick={() => navigate(buildUrl(urlPath))}
              >
                <Tag isEvent={category.name === 'Events'}>{category.name}</Tag>
                <StyledHeading>
                  <Link to={urlPath}>{title}</Link>
                </StyledHeading>
                <StyledExcerpt>{excerpt}</StyledExcerpt>
                <StyledLink to={urlPath}>Read more</StyledLink>
              </StyledBlogCard>
            );
          })}
        </StyledBlogCards>
      </Container>
    </StyledBlogListing>
  );
};

export default BlogListing;

export const BlogListingItemFragment = graphql`
  fragment BlogListingItemFragment on DatoCmsBlog {
    title
    slug
    excerpt
    category {
      name
      backgroundColour {
        hex
      }
    }
  }
`;
